import { ChangeDetectionStrategy, Component, TrackByFunction } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import { TagsState } from "@vp/data-access/tags";
import { SchemaFieldType } from "@vp/formly/json-schema";
import { Tag, TagsArray, User, UserRole } from "@vp/models";
import * as UserAdministrationStateActions from "@vp/user-administration/data-access/user-administration-state";
import { UserAdministrationState } from "@vp/user-administration/data-access/user-administration-state";
import { createPatch } from "rfc6902";
import { BehaviorSubject, map, Observable, Subject, takeUntil, tap } from "rxjs";

@Component({
  selector: "vp-specialty-tags",
  templateUrl: "./specialty-tags.component.html",
  styleUrls: ["./specialty-tags.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpecialtyTagsComponent extends SchemaFieldType {
  @Select(TagsState.tags) tags$!: Observable<Tag[]>;
  private readonly _selectedOptions$ = new BehaviorSubject<string[]>([]);
  selectedOptions$ = this._selectedOptions$.asObservable();

  constructor(private readonly store: Store) {
    super();
  }

  private readonly _destroyed$ = new Subject<void>();

  specialtyTags$ = this.tags$.pipe(
    takeUntil(this._destroyed$),
    map((tags: Tag[]) => {
      const specialties = tags.filter(x => x.tagTypeFriendlyId === "specialty");
      return specialties;
    }),
    tap(specialties => {
      const user = this.store.selectSnapshot(UserAdministrationState.user);
      const accessTags = user?.roles?.map(x => (x.accessTags ? x.accessTags : [])) || [];
      const accessTagsFlattened = this.flattenArray<TagsArray>(accessTags);
      const tags = accessTagsFlattened.map(x => x.tags);
      const commonElements = this.findCommonElements(tags);
      const checkTags = commonElements.filter(item => user?.assignedTags.includes(item));
      const selected = specialties.filter(x => checkTags.includes(x.tagId)).map(x => x.tagId);
      this._selectedOptions$.next(selected);
    })
  );

  flattenArray<T>(inputArray: T[][]): T[] {
    const flattenedArray: T[] = [];
    for (let i = 0; i < inputArray.length; i++) {
      for (let j = 0; j < inputArray[i].length; j++) {
        flattenedArray.push(inputArray[i][j]);
      }
    }
    return flattenedArray;
  }

  findCommonElements(arrays: string[][]): string[] {
    if (arrays.length === 0) {
      return [];
    }

    // Start with the first array
    return arrays.reduce((acc, curr) => {
      return acc.filter(element => curr.includes(element));
    });
  }

  onTagsChanged(event: any) {
    const user = this.store.selectSnapshot(UserAdministrationState.user);
    if (user) {
      this._selectedOptions$.next(event.value);
      this.store.dispatch(
        new UserAdministrationStateActions.UpdateWorkingCopy({
          assignedTags: event.value,
          roles: this.modifyUserRoles(event.value, user)
        })
      );
      this.updateOperations();
    }
  }

  modifyUserRoles(mergedArray: string[], user: User): UserRole[] {
    const userRoles = [...(user.roles || [])];
    const userRoleUpdated = userRoles.map(role => {
      const updatedAccessTags =
        mergedArray.length > 0 ? ([{ tags: mergedArray }] as TagsArray[]) : [];
      return {
        accessTags: updatedAccessTags,
        departments: role.departments,
        friendlyId: role.friendlyId,
        roleId: role.roleId
      };
    }) as unknown as UserRole[];

    return userRoleUpdated;
  }

  updateOperations() {
    const original = this.store.selectSnapshot(UserAdministrationState.user);
    const workingCopy = this.store.selectSnapshot(UserAdministrationState.getWorkingCopy);

    if (!original || !workingCopy) return;

    const operations = createPatch(original, workingCopy);

    this.store.dispatch(new UserAdministrationStateActions.SetPendingOperations(operations));
  }

  trackTagByFn: TrackByFunction<Tag> = (_index: number, item: Tag) => item.tagId;
}

<mat-form-field>
  <button
    *ngIf="inputText.length > 0"
    matPrefix
    mat-icon-button
    (click)="clear()"
    type="button"
    matTooltip="Reset"
  >
    <mat-icon color="accent" aria-hidden="false" class="icon" inline="true">close</mat-icon>
  </button>
  <mat-label>Search</mat-label>
  <input
    title="Search Currently Displayed Results &#10;  &#x23ce;  Press Enter to Search &#10; &#x232b; Press Delete to Clear"
    id="searchInput"
    #searchinput
    matInput
    [placeholder]="inputPlaceholder"
    (keyup)="checkIfEmpty($event)"
    (keyup.enter)="enterPress()"
    [(ngModel)]="inputText"
    (ngModelChange)="changed()"
    type="search"
    autofocus
    autocomplete="off"
  />
  <button
    id="submitButton"
    matSuffix
    type="submit"
    mat-icon-button
    [disableRipple]="true"
    matTooltip="Perform search or [ENTER]"
    (click)="searchClicked()"
  >
    <mat-icon
      aria-hidden="false"
      class="icon"
      inline="true"
      [color]="focused ? 'accent' : 'primary'"
      >search
    </mat-icon>
  </button>
</mat-form-field>

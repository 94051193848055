<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{
    specialtyTags: (specialtyTags$ | async),
    selectedOptions: (selectedOptions$ | async)
  }"
>
</ng-container>

<ng-template #template let-specialtyTags="specialtyTags" let-selectedOptions="selectedOptions">
  <mat-form-field>
    <mat-label>Specialties</mat-label>
    <mat-select
      #specialtyId
      (selectionChange)="onTagsChanged($event)"
      [value]="selectedOptions"
      [multiple]="true"
    >
      <mat-option
        *ngFor="let module of specialtyTags; trackBy: trackTagByFn"
        [value]="module.tagId"
        >{{ module.displayName }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</ng-template>

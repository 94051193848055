<mat-form-field>
  <mat-label>{{ props.label }}</mat-label>
  <input
    [formControl]="formControl"
    matInput
    [required]="props.required ? true : false"
    [max]="maxDate"
    [matDatepicker]="picker"
    (dateChange)="formatDate($event)"
    [errorStateMatcher]="this"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-hint>{{ props.hint }}</mat-hint>
  <mat-error *ngIf="formControl.hasError('required')">This field is required</mat-error>
  <mat-error *ngIf="formControl.hasError('maxDate')">Date out of range</mat-error>
</mat-form-field>

import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  ParamMap,
  QueryParamsHandling,
  Router
} from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import { TagsState } from "@vp/data-access/tags";
import { CaseData, Tag, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { TwoWayMap, objectsEqual, stringToNumber } from "@vp/shared/utilities";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import {
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import { UserFilter } from "../models/user-filter";
import * as UserStateActions from "../state+/user-state.actions";
import { UserState } from "../state+/user.state";

// TODO: Not sure what this is, its weird.
export const vmToDbColumnMap = new TwoWayMap({
  userName: "profile.firstName",
  userEmail: "email",
  userLastLoginDate: "lastLoginDateTime",
  userState: "active"
});

const useDefaultTagTypes = ["facility", "hub"];

export const USER_STATE_DEFAULT_PAGER_LIST = [5, 10, 25, 100];
export const USER_STATE_DEFAULT_PAGER_SKIP = 0;
export const USER_STATE_DEFAULT_PAGER_TAKE = 25;

@Injectable()
export class UserStateService implements OnDestroy {
  @Select(UserState.users) users$!: Observable<CaseData[]>;
  @Select(UserState.currentFilter) currentFilter$!: Observable<UserFilter>;
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;
  @Select(TagsState.filtered) tags$!: Observable<Tag[]>;

  useDefaultTagTypes$ = new BehaviorSubject<string[]>(useDefaultTagTypes);

  private _destroyed$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  newUrlEvent$ = this.router.events.pipe(
    takeUntil(this._destroyed$),
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(event => event.url.split("?")[0])
  );

  previousUrlEvent$ = this.router.events.pipe(
    takeUntil(this._destroyed$),
    filter((event): event is NavigationStart => event instanceof NavigationStart),
    map(event => event.url.split("?")[0])
  );

  /*  To prevent unncessary navigations when resetting the user state filter
      we keep track of router events to determine if navigation is occuring to a different page
      or if due to the user state filter changing. */
  isDifferentRoute$ = combineLatest([this.previousUrlEvent$, this.newUrlEvent$]).pipe(
    takeUntil(this._destroyed$),
    map(([previousUrl, newUrl]) => {
      return previousUrl !== newUrl;
    }),
    //This is to ensure the state sets the nav atleast once on listen()
    startWith(false)
  );

  userDataDefaultTags$ = combineLatest([
    this.loggedInUser$.pipe(filterNullMap()),
    this.tags$,
    this.useDefaultTagTypes$
  ]).pipe(
    map(
      ([user, tags, useDefaultTagTypes]: [User, Tag[], string[]]): Record<string, Tag[]> =>
        useDefaultTagTypes.reduce((acc: Record<string, Tag[]>, tagType: string) => {
          const defaults =
            user.userData?.tagFilters?.filter(t => t.tagFriendlyId === tagType).map(t => t.tagId) ||
            [];
          const defaultTags = tags.filter(t => defaults.includes(t.tagId));
          acc[tagType] = defaultTags;
          return acc;
        }, {})
    )
  );

  routeParams$ = this.activatedRoute.queryParamMap.pipe(
    map(queryParamMap => this.getParamsForApi(queryParamMap))
  );

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  initialize(staticPresets?: Partial<UserFilter> | null | undefined) {
    this.handleQueryParamChanges(staticPresets).subscribe({
      error: err => {
        this.store.dispatch(
          new UserStateActions.PatchState({
            errors: [err]
          })
        );
      }
    });

    this.currentFilter$
      .pipe(
        distinctUntilChanged((prev, curr) => objectsEqual(prev, curr)),
        //Ignore navigation caused by the user state filter if we are navigating away from the page
        withLatestFrom(this.isDifferentRoute$),
        takeUntil(this._destroyed$)
      )
      .subscribe(([filter, skipFilterNavigation]: [UserFilter, boolean]) => {
        if (!skipFilterNavigation) {
          this.navigate(filter);
        }
      });
  }

  listen(staticPresets?: Partial<UserFilter> | null | undefined) {
    this.handleQueryParamChanges(staticPresets).subscribe(() => {
      this.store.dispatch(new UserStateActions.GetFiltered());
    });
  }

  navigate(
    searchParams: Partial<UserFilter>,
    queryParamsHandling: QueryParamsHandling | null = "",
    skipLocationChange = false,
    replaceUrl = true
  ): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: searchParams,
      queryParamsHandling,
      skipLocationChange,
      replaceUrl
    });
  }

  private handleQueryParamChanges(staticPresets: Partial<UserFilter> | null | undefined) {
    const staticUserType = staticPresets?.userType ?? [];

    return this.routeParams$.pipe(
      concatMap(({ skip, sortByField, sortDirection, search, filters, tags }) => {
        const currentFilter = this.store.selectSnapshot(UserState.currentFilter);

        return this.store.dispatch(
          new UserStateActions.SetFilter({
            take: currentFilter.take,
            skip: skip,
            sort: sortByField,
            sortDirection: sortDirection,
            search: search,
            filters: [...filters],
            tags: tags,
            userType: [...staticUserType]
          })
        );
      }),
      takeUntil(this._destroyed$)
    );
  }

  private getParamsForApi(paramMap: ParamMap) {
    const take = stringToNumber(paramMap.get("take")) ?? undefined;
    const skip = stringToNumber(paramMap.get("skip")) ?? undefined;
    const sortByField = paramMap.get("sort") ?? undefined;
    const sortDirection = paramMap.get("sortDirection") ?? undefined;
    const search = paramMap.get("search") ?? undefined;
    const filters = paramMap.getAll("filters") ?? undefined;
    if (paramMap.get("role") !== null) {
      // Clear existing "role" filter before pushing the new one
      const roleFilterIndex = filters.findIndex(filter => filter.startsWith("roles.roleId="));
      if (roleFilterIndex !== -1) {
        filters.splice(roleFilterIndex, 1);
      }
      if (paramMap.get("role") !== "all") {
        filters.push(`roles.roleId=${paramMap.get("role")}`);
      }
    }
    if (paramMap.get("active") !== null) {
      // Clear existing "active" filter before pushing the new one
      const activeFilterIndex = filters.findIndex(filter => filter.startsWith("active="));
      if (activeFilterIndex !== -1) {
        filters.splice(activeFilterIndex, 1);
      }
      if (paramMap.get("active") !== "all") {
        filters.push(`active=${paramMap.get("active") == "Active" ?? false}`);
      }
    }
    if (paramMap.get("group") !== null && paramMap.get("group") !== "all") {
      filters.push(`groups.groupId=${paramMap.get("group")}`);
    }
    const tags: string[] = paramMap.getAll("tags").filter((name: string) => name !== "all");
    return { take, skip, sortByField, sortDirection, search, filters, tags };
  }
}

import { Injectable } from "@angular/core";
import { Select, Store } from "@ngxs/store";
import * as ApplicationStateActions from "@vp/data-access/application";
import { Alert, ApplicationState } from "@vp/data-access/application";
import { User } from "@vp/models";
import { AlertItem } from "@vp/shared/components/alert";
import { FeatureService } from "@vp/shared/features";
import { AppStoreService } from "@vp/shared/store/app";
import { deepCopy } from "@vp/shared/utilities";
import { Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class SystemBannerAdapter {
  @Select(ApplicationState.alerts) alerts$!: Observable<Alert[]>;
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;

  constructor(
    private featureService: FeatureService,
    private appStoreService: AppStoreService,
    private store: Store
  ) {
    this.featureService
      .configurationDictionaryValue$<Alert>("systemBanner", "activeAlerts")
      .pipe(filter(alerts => alerts?.length > 0))
      .subscribe(alerts => {
        this.store.dispatch(new ApplicationStateActions.AddAlert(alerts));
      });
  }

  banners$: Observable<AlertItem[]> = this.alerts$.pipe(
    filter(alerts => alerts.length > 0),
    map((systemAlerts: Alert[]) => {
      const user = this.store.selectSnapshot(ApplicationState.loggedInUser);
      if (!user || !user?.userData) return [];
      const acknowledgedAlerts = Object.keys(user.userData).includes("acknowledgedAlerts")
        ? (user.userData["acknowledgedAlerts"] as string[]) ?? []
        : [];
      const results = systemAlerts
        .filter(a => {
          const date = new Date();
          const startDate = new Date(a.startDate);
          const endDate = new Date(startDate);
          endDate.setDate(startDate.getUTCDate() + a.duration);

          if (
            a.roles.some(r => user?.roles.map(r => r.friendlyId).includes(r)) &&
            date >= startDate &&
            date <= endDate &&
            acknowledgedAlerts.includes(a.id) === false
          ) {
            return true;
          }
          return false;
        })
        .map(alert => {
          return {
            id: alert.id,
            type: "toast-info",
            message: alert.message,
            title: alert.title
          } as AlertItem;
        });

      return results;
    })
  );

  dismissBanner(alertItem: AlertItem) {
    const user = this.store.selectSnapshot(ApplicationState.loggedInUser);
    if (user) {
      const modifiedUser = deepCopy(user);
      if (user.userData) {
        modifiedUser.userData.acknowledgedAlerts ??= [];
        modifiedUser.userData.acknowledgedAlerts.push(alertItem.id);
      }
      this.appStoreService.patchUser(modifiedUser, "user_updateSelf").pipe(take(1)).subscribe();
    }
  }
}
